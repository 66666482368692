<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          variant="success"
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <form class="needs-validation" @submit.prevent="formDatas">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="branchId">สาขา:</label>

                        <multiselect
                          disabled
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          class=""
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="handleSearch"
                        ></multiselect>
                      </div>
                    </div> -->
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขที่เอกสาร:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.regJobCode"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="reqDate"> วันที่:</label>

                        <date-picker
                          disabled
                          v-model="form.sendDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="mb-3 position-relative">
                            <label for="regCode">
                              เลือกจากเลขที่ใบแจ้งจำหน่าย:</label
                            >
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.regCode"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12">
                          <div class="mb-3 position-relative">
                            <label for="customerNameTh">ชื่อลูกค้า:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.customerFullNameTh"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="vehicleId">ทะเบียนรถ:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.vehicleId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขตัวถัง:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.vin"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="modelNameTh">รุ่น:</label>

                        <b-form-input
                          disabled
                          id="modelNameTh"
                          v-model="form.modelNameTh"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="paymentId">เลขที่ใบเสร็จรับเงิน:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.paymentId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-4">
                      <div class="mb-2">
                        <label class="form-label d-block mb-3"></label>
                        <b-form-radio-group
                          disabled
                          v-model="form.isSale"
                          :options="optionSale"
                          name="radios-stacked"
                          stacked
                        >
                        </b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          disabled
                          v-model="form.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <br />
                  <!-- <div class="col-md">
                    <button
                      :disabled="this.form.status !== 'BRCUS'"
                      class="btn btn-success float-end"
                      type="submit"
                    >
                      บันทึก
                    </button>
                  </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>

              <document-component
                :dataImg="apiImg"
                :dataCheck="apiCheck"
                :dataShow="apiShow"
                :receivedBy="receivedBy"
                :isReceived="isReceived"
              >
              </document-component>
            </b-skeleton-wrapper>

            <hr />
            <div class="row mb-4 mt-4">
              <div class="col text-end ms-1">
                <span><code>*** กรุณาระบุรับเอกสารให้ครบก่อนยืนยัน</code></span
                ><br />
                <button
                  class="btn btn-danger ms-1"
                  type="submit"
                  @click="alertSubmit()"
                >
                  ยืนยันการส่งเอกสาร
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
    <b-modal
      ref="modalImg"
      id="modalImg"
      title="ภาพประกอบ"
      hide-footer
      size="l"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <img
                :src="this.imagePath"
                class="preview-img"
                @click="singleImage = true"
              />
              <vue-easy-lightbox
                :visible="singleImage"
                :imgs="this.imagePath"
                @hide="singleImage = false"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
import documentComponent from "@/components/documentComponent";

// import btnComponent from "@/components/btnComponent.vue";
// import ChangeDate from "@/components/changeDateComponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    DatePicker,
    documentComponent,
    // btnComponent,
    // ChangeDate,
  },
  page: {
    title: appConfig.sendDocCus,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      apiShow: "api/reg-send-cus/show",
      apiImg: "api/reg-send-cus/full-size-image",
      apiCheck: "api/reg-send-cus/received-doc",
      isReceived: "ส่งเอกสาร",
      receivedBy: "ผู้ส่ง",
      overlayFlag: false,
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      singleImage: false,
      imagePath: "",
      loadingPer: false,
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "สร้างการส่งเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "ส่งเอกสารคืนลูกค้า",
          href: "/send-docCus",
        },
        {
          text: "สร้างการส่งเอกสาร",
          active: true,
        },
      ],
      rowData: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      rowDocType: [],
      filter: {
        startDate: "",
        endDate: "",
        branchId: "",
        licensePlate: "",
        regCode: "",
        rcvdCode: "",
        docTypeId: "",
      },
      fields: [
        {
          key: "index",
          label: "เลือก",
        },
        {
          key: "regCode",
          sortable: true,
          label: "เลขที่ใบแจ้งจำหน่าย",
        },
        {
          key: "sendCode",
          sortable: true,
          label: "เลขที่รับเอกสาร",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "docTypeNameTh",
          sortable: true,
          label: "ประเภทเอกสาร",
        },
        {
          key: "sendDate",
          sortable: true,
          label: "วันที่รับเอกสาร",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPerson: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "personCode",
          sortable: true,
          label: "เลขที่พนักงาน",
        },
        {
          name: "customerNameTh",
          sortable: true,
          label: "ชื่อพนักงาน",
        },
        {
          key: "familyName",
          sortable: true,
          label: "นามสกุลพนักงาน",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "email",
          sortable: true,
          label: "อีเมล",
        },
      ],
      rowPerson: [],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],
      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      totalRecordRe: "",
      rowTest: [],
      totalRows: 1,
      regJobId: this.$route.params.regJobId,
      document: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],

      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsData: [],
      optionTypeDoc: [],
      form: {
        docId: [],
        branchId: "",
        sendDate: "",
        personId: "",
        note: "",
        regJobCode: "",
        regCode: "",
        customerFullNameTh: "",
        vehicleId: "",
        vin: "",
        modelNameTh: "",
        paymentId: "",
        isSale: "",
      },

      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      docId: {
        required,
      },
      sendDate: {
        required,
      },
      branchId: {
        required,
      },
      // cause: {
      //   required,
      // },
      // customerDecision: {
      //   required,
      // },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = { nameTh: item.nameTh, branchId: item.branchId };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getDataShow();
    this.getNow();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.form.sendDate = date;
    },
    customLabelPer({ name, familyName, personCode }) {
      return `${name != null ? name : ""}  ${
        familyName != null ? familyName : " "
      }-[${personCode != null ? personCode : ""}  ]`;
    },
    onRowSelected(data) {
      //(data);
      this.selected = data[0];

      var personId = {
        name: this.selected.name,
        familyName: this.selected.familyName,
        personCode: this.selected.personCode,
        personId: this.selected.personId,
      };
      this.form.personId = personId;
      // this.form.branchId = branchName;
      this.$refs["modalPerson"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRo();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get(this.apiShow, {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.form.sendDate = response.data.data.regJobDate;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };

          this.form.note = response.data.data.note;
          this.form.status = response.data.data.status;
          this.form.doc = response.data.data.docCode;
          this.form.isSale = response.data.data.isSale;
          this.form.regJobCode = response.data.data.regJobCode;

          this.form.customerFullNameTh = `${
            response.data.data.customerNameTh
          } ${
            response.data.data.customerFamilyNameTh != null
              ? response.data.data.customerFamilyNameTh
              : ""
          }`;
          this.form.vehicleId = response.data.data.licensePlate;

          this.form.vin = response.data.data.vin;
          this.form.modelNameTh = response.data.data.modelNameTh;
          this.form.paymentId = response.data.data.paymentCode;
          this.form.regCode = response.data.data.regCode;

          this.document = response.data.data.document;
          // this.getDocCus();
          // this.getDocSend();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataloadImage: function (data) {
      this.fileId = data.fileId;
      useNetw
        .get(this.apiImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    putCheck(docId) {
      useNetw
        .put(this.apiCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    putSubmit() {
      useNetw
        .put("api/reg-send-cus/submit", {
          regJobId: atob(this.regJobId),
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({ name: "sendDocCus" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getRecDoc: function () {
      this.loading = true;
      useNetw
        .get("api/reg-send-cus/branch-reg-rcvd-reg-dept", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId != null
                ? this.form.branchId.branchId
                : this.branchId,
            startDate: this.filter.startDate,
            sendCode: this.filter.rcvdCode,
            endDate: this.filter.endDate,
            regCode: this.filter.regCode,
            licensePlate: this.filter.licensePlate,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
          },
        })
        .then((response) => {
          //(response);
          this.rowsData = response.data.data;
           const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDocType: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-send-cus/branch-document-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId != null
                ? this.form.branchId.branchId
                : this.branchId,
            startDate: this.filter.startDate,
            rcvdCode: this.filter.rcvdCode,
            endDate: this.filter.endDate,
            regCode: this.filter.regCode,
            licensePlate: this.filter.licensePlate,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
          },
        })
        .then((response) => {
          this.rowDocType = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    formDatas() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postSubmit();
      }
    },
    postSubmit() {
      useNetw
        .post("api/reg-send-cus/store", {
          sendDate: this.form.sendDate,
          note: this.form.note,
          branchId: this.form.branchId.branchId,
          docId: this.form.docId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "sendDocCus" });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId.branchId;
      }
      this.getRecDoc();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRecDoc();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRecDoc();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
